<template>
  <div class="about">
    <Paragraph />
  </div>
</template>

<script>
import Paragraph from "../components/About/Paragraph";

export default {
  components: { Paragraph },
  name: "About",
};
</script>

<style scoped></style>;
