<template>
  <div class="contact">
    <ContactCard />
  </div>
</template>

<script>
import ContactCard from "../components/Contact/ContactCard";
export default {
  name: "Contact",
  components: {
    ContactCard
  }
};
</script>