<template>
  <div class="hire">
    <Paragraph />
  </div>
</template>

<script>
import Paragraph from "../components/Hire/Paragraph";
export default {
  name: "Hire",
  components: {
    Paragraph
  }
};
</script>


<style scoped>
</style>;
