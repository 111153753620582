<template>
  <div class="footerFont">
    &copy; {{ new Date().getFullYear() }} Copyright Afton Gauntlett
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.footerFont {
  color: #ddd;
  background-color: #282e34;
  padding: 20px;
}
</style>
